html, body, svg {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow:hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFF8;
}

p {
  position: fixed;
  left: 0;
  height: 60px;
  bottom: 0;
  background-color: #007B85;
  width: 100%;
  color: white;
  text-align: center;
  padding-top: 20px;
}

p:hover {
  cursor: pointer;
}

svg {
  position: fixed;
  top: 0;
  left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 720px) {
  p {
    font-size: .8rem;
  }
}
