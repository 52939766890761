.body {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 300px;
}

.text-banner {
  text-align: center;
}

.content-block {
  margin-bottom: 50px;
}

.content-block p {
  margin-left: 15%;
  margin-right: 15%;
  font-size: 20px;
}

.content-break {
  margin-bottom: 200px;
}

.content-row {
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  justify-content: space-evenly;
}

.content-column {
  /* flex-grow: 1; */
  width: 50%;
}

.col button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.right-element {
  margin-left: 20px;
}
